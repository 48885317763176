import styled from 'styled-components';
import {Link} from 'gatsby';
import {NAVIGATION_COLLAPSE_POINT} from '../../constants/layouts';

interface FixedProps {
  shadow: boolean;
}

export const Fixed = styled.header<FixedProps>`
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  box-shadow: ${(p: FixedProps) =>
    p.shadow ? '1px 1px 20px #d5d5d5' : 'none'};
  transition: box-shadow 0.3s;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 30px 0px;

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 1fr 0 29px 0;
    grid-template-rows: 1fr;
  }
`;

export const Logo = styled.svg`
  width: 149px;
  height: 22px;
  fill: #333;
  transform: scale(1);
  transition: fill 0.3s, transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  cursor: pointer;
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  border-bottom: 0px;

  &:hover {
    border-bottom: 0px;
  }
`;
