import styled from 'styled-components';
import {Link} from 'gatsby';
import Colours from '../../constants/colours';
import {Button} from '../Button';
import {NAVIGATION_COLLAPSE_POINT} from '../../constants/layouts';

export const activeStyle = {borderBottom: `3px solid ${Colours.black}`};

export const Wrapper = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  & button {
    margin-left: 2rem;
  }

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    justify-content: flex-end;
  }
`;

export const NavLink = styled(Link)`
  font-size: 1.2rem;
  margin-right: 2rem;
  /* offsetting the border bottom for better alignment in the nav */
  margin-top: 0.5em;
  border-top: 2px solid ${Colours.white};

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    display: none;
  }
`;

export const MobileMenuIcon = styled.svg`
  display: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  width: 24px;
  height: 23px;

  &:active {
    background-color: ${Colours.grey[100]};
  }

  & rect {
    width: 29px;
    height: 1px;
  }

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    display: flex;
    justify-content: space-around;
  }
`;

interface MobileWrapperProps {
  open: boolean;
}

export const MobileWrapper = styled.div<MobileWrapperProps>`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: ${(props) =>
    props.open ? 'translateY(0%)' : 'translateY(-100%)'};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 0.2s cubic-bezier(0.01, 0.1, 0.3, 1);
  background-color: ${Colours.white};
`;

interface MobileNavLinkProps {
  $isMenuOpen: boolean;
}

export const MobileNavLink = styled(NavLink)<MobileNavLinkProps>`
  margin: 0 1em;
  border-bottom: 0px;
  opacity: ${({$isMenuOpen}) => ($isMenuOpen ? 1 : 0)};
  transform: ${({$isMenuOpen}) =>
    $isMenuOpen ? 'translateX(0.8em)' : 'translateX(-1em)'};
  transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.3, 1) 0.3s,
    transform 0.3s cubic-bezier(0.1, 0.1, 0.3, 1) 0.3s;
  padding: 1em 0em;

  &:hover {
    border-bottom: 0px;
  }

  &:first-child {
    margin-top: 2em;
  }

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    display: block;
  }
`;

export const LogoutButton = styled(Button)`
  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    display: none;
  }
`;
