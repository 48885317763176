import React, {FunctionComponent, useState} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import ExternalLink from '../ExternalLink';
import {ButtonVariant} from '../Button';
import {
  Wrapper,
  NavLink,
  MobileMenuIcon,
  MobileWrapper,
  MobileNavLink,
  LogoutButton,
  activeStyle,
} from './Navigation.styles';
import {useAuth} from '../../services/auth';

export const Navigation: FunctionComponent = () => {
  const {isLoggedIn, logout} = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const getLink = (name: string) => {
    return name.toLowerCase().replace(' ', '-');
  };

  const handleLogout = () => {
    logout();
    window.location.assign('/');
  };

  const renderSupportLink = (classNames: string[]) => (
    <ExternalLink
      testId="support-link"
      title="Ampify Support"
      classNames={classNames}
      href="https://support.ampifymusic.com/"
    >
      Support
    </ExternalLink>
  );

  const renderNavLink = (name: string) => (
    <NavLink
      to={`/${getLink(name)}/`}
      activeStyle={activeStyle}
      data-cy={`${name.toLowerCase()}-link`}
    >
      {name}
    </NavLink>
  );

  const renderMobileNavLink = (name: string) => (
    <MobileNavLink
      $isMenuOpen={isMenuOpen}
      to={`/${getLink(name)}/`}
      onClick={() => setIsMenuOpen(false)}
      style={{transitionDelay: `0.1s`}}
    >
      {name}
    </MobileNavLink>
  );
  return (
    <>
      <Wrapper data-cy="navigation">
        {renderNavLink('Products')}
        {renderNavLink('Ampify Studio')}
        {renderNavLink('Ampify Sounds')}
        {renderNavLink('The Hub')}
        {renderNavLink('My Account')}
        {renderSupportLink(['external_nav_link'])}
        {isLoggedIn ? (
          <LogoutButton
            variant={ButtonVariant.BLACK_OUTLINE}
            onClick={handleLogout}
            data-test-id="logoutButton"
          >
            Log out
          </LogoutButton>
        ) : (
          ''
        )}
      </Wrapper>
      <MobileMenuIcon
        onClick={() => setIsMenuOpen(true)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 14"
      >
        <path
          fill="#000"
          fillRule="nonzero"
          d="M0 0h20v2H0V0zm0 6h20v2H0V6zm0 6h20v2H0v-2z"
        />
      </MobileMenuIcon>
      <MobileWrapper open={isMenuOpen} onClick={() => setIsMenuOpen(false)}>
        {renderMobileNavLink('Products')}
        {renderMobileNavLink('Ampify Studio')}
        {renderMobileNavLink('Ampify Sounds')}
        {renderMobileNavLink('The Hub')}
        {renderMobileNavLink('My Account')}
        {isMenuOpen
          ? renderSupportLink(['mobile_external_nav_link', 'show'])
          : renderSupportLink(['mobile_external_nav_link'])}
        {isLoggedIn ? renderMobileNavLink('Logout') : ''}
      </MobileWrapper>
    </>
  );
};
