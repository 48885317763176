import styled from 'styled-components';
import {Link} from 'gatsby';

export const Background = styled.footer`
  background-color: #111;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 70px 1fr 1fr;
  align-items: flex-start;
  padding: 4rem 0;
  color: #ccc;

  ul {
    list-style-type: none;
    line-height: 1.5;
  }

  li {
    padding: 0.3em;

    & :nth-child(4n + 1) {
      padding-top: 0px;
    }
  }

  a {
    border: none;
    color: #ccc;

    &:hover {
      border: none;
      color: #fff;
    }
  }

  @media (max-width: 800px) {
    grid-gap: 0px;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr 1fr;
  }
`;

export const LogoLink = styled(Link)`
  border-bottom: none;
  padding-bottom: 0;
  &:hover {
    border-bottom: none;
  }
`;

export const LogoBG = styled.path`
  fill: #fff;
  cursor: pointer;
  transition: fill 0.5s cubic-bezier(0, 0, 0.3, 1);
`;

export const LogoText = styled.path`
  fill: #111;
`;

export const Logo = styled.svg`
  width: 70px;
  height: 70px;

  &:hover ${LogoBG} {
    fill: #333;
  }

  &:hover ${LogoText} {
    fill: #fff;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    margin-right: 1em;
  }

  a:last-child {
    margin-right: 0;
  }

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const SocialIcon = styled.svg`
  width: 48px;
  height: 48px;
  cursor: pointer;

  path:last-child,
  path:first-child {
    transition: fill 0.1s linear;
  }
`;

export const Facebook = styled(SocialIcon)`
  &:hover path:last-child,
  &:active path:last-child {
    fill: #3a589b;
  }

  &:hover path:first-child,
  &:active path:first-child {
    fill: #fff;
  }
`;

export const Instagram = styled(SocialIcon)`
  #igBackground {
    fill: #fff;
  }

  g {
    fill: #333;
  }

  &:hover #igBackground,
  &:active #igBackground {
    fill: #954bd3;
  }

  &:hover g,
  &:active g {
    fill: #fff;
  }
`;

export const Twitter = styled(SocialIcon)`
  path:first-child {
    fill: #333;
  }

  path:last-child {
    fill: #fff;
  }

  &:hover path:first-child,
  &:active path:first-child {
    fill: #fff;
  }

  &:hover path:last-child,
  &:active path:last-child {
    fill: #1db6ea;
  }
`;

export const Vimeo = styled(SocialIcon)`
  path:first-child {
    fill: #333;
  }

  path:last-child {
    fill: #fff;
  }

  &:hover path:first-child,
  &:active path:first-child {
    fill: #fff;
  }

  &:hover path:last-child,
  &:active path:last-child {
    fill: #1eaee4;
  }
`;

export const YouTube = styled(SocialIcon)`
  #box {
    fill: #333;
  }

  #play {
    fill: #fff;
  }

  &:hover #box {
    fill: #fff;
  }

  &:hover #play {
    fill: #e02f2f;
  }
`;

export const Discord = styled(SocialIcon)`
  background-color: #fff;

  & :hover {
    background-color: #954bd3;
  }

  & :hover path {
    fill: #fff;
  }
`;

export const LinkContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  max-width: max-content;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 4rem;

    > div {
      padding: 0.5rem;
    }
  }
`;
