import React, {FunctionComponent} from 'react';
import {Wrapper} from './Fuel.styles';

interface FuelProps {
  fuelText: string;
  variableCollection: string[];
}

export const Fuel: FunctionComponent<FuelProps> = React.memo(
  ({fuelText, variableCollection}) => {
    const fuel = variableCollection.length
      ? variableCollection[
          Math.floor(Math.random() * variableCollection.length)
        ]
      : 'pickles';

    return <Wrapper>{fuelText.replace('$replace_me', fuel)}</Wrapper>;
  }
);
