import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: right;
  width: 100%;
  line-height: 1.5;
  margin-top: 0.7em;

  @media (max-width: 800px) {
    text-align: center;
  }
`;
