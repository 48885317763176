import React, {ReactNode} from 'react';
import styled from 'styled-components';

type PageWidthProps = {
  children: ReactNode;
  narrow?: boolean;
  wide?: boolean;
};

const PageWidth = (props: PageWidthProps) => (
  <Wrapper {...props}>{props.children}</Wrapper>
);

const Wrapper = styled.div<PageWidthProps>`
  width: 95%;
  max-width: ${(p) => (p.narrow ? '700px' : p.wide ? '1400px' : '1000px')};
  margin: auto;

  @media (max-width: 1000px) {
    width: ${(p) => (p.narrow ? '85%' : p.wide ? '95%' : '85%')};
  }

  @media (max-width: 500px) {
    width: ${(p) => (p.narrow ? '85%' : p.wide ? '95%' : '85%')};
  }
`;

export default PageWidth;
