import React, {FunctionComponent, ReactNode} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {useLocation} from '@reach/router';
import Helmet from 'react-helmet';
import {shouldInjectCountly, getCountlySrc} from '../../utils/countly';
import {Header} from '../Header';
import {Footer} from '../Footer';
// @ts-ignore
import socialShareImage from '../../images/social-share.jpg';
import {getBuildEnvFromData} from '../../utils';
import {Content} from './Layout.styles';
import {GlobalStyles} from '../../theme/GlobalStyles';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FunctionComponent<LayoutProps> = ({children}) => {
  const location = useLocation();
  return (
    <StaticQuery
      query={graphql`
        query BuildEnvQuery {
          site {
            siteMetadata {
              buildEnv
              siteUrl
            }
          }
          allContentfulAmpifyWebFooter(filter: {node_locale: {eq: "en-GB"}}) {
            edges {
              node {
                links
                footerFuelText
                footerFuelVariables
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <GlobalStyles />
          <Helmet>
            <meta
              property="og:url"
              content={`${data.site?.siteMetadata?.siteUrl}${location.pathname}`}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Ampify for iOS - Music Making Apps"
            />
            <meta
              property="og:description"
              content="Make, record and remix music &amp; beats"
            />
            <meta property="og:image" content={socialShareImage} />
            <meta
              name="facebook-domain-verification"
              content="8a42i1r4mrhk8adox7651f9t7wxyxy"
            />
            {shouldInjectCountly() ? (
              <script
                type="text/javascript"
                src={getCountlySrc(getBuildEnvFromData(data))}
              ></script>
            ) : null}
          </Helmet>
          <Header />
          <Content spacing={97}>{children}</Content>
          <Footer footer={data.allContentfulAmpifyWebFooter.edges[0].node} />
        </>
      )}
    />
  );
};
